import React, { useEffect } from "react";

import styles from "./styles/Dashboard.module.scss";

import { SubTitle } from "../../Components/CustomComponents";
import { Row, Col } from "react-bootstrap";
import DashboardCard from "../../Components/Cards/DashboardCard";
import { FaUserNurse } from "react-icons/fa6";
import { IoRecording, IoRecordingOutline } from "react-icons/io5";
import GlobalStyles from "../../_global.scss";
import { useGetDashboardDetailsQuery } from "../../services/dashboardApi";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardInfo } from "../../features/dashboardSlice";

export default function Dashboard({ setPage }) {
  const { primaryColor, primaryColorWithAlpha } = GlobalStyles;
  const dispatch = useDispatch();

  const { data, isSuccess } = useGetDashboardDetailsQuery({
    refetchOnMountOrArgChange: true,
  });

  const dashboardInfo = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(
        setDashboardInfo({
          user_count: data.data.user_count,
          recording_count: data.data.recording_count,
          pending_recording_count: data.data.pending_recording_count,
        })
      );
    }
  }, [data, isSuccess, dispatch]);


  return (
    <div className={styles.Dashboard}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Dashboard"} />
        </div>
      </div>

      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={primaryColor}
            bg2={primaryColorWithAlpha}
            icon={<FaUserNurse color="#fff" />}
            number={dashboardInfo.user_count}
            text={"Numbers of Users"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={primaryColor}
            bg2={primaryColorWithAlpha}
            icon={<IoRecording color="#fff" />}
            number={dashboardInfo.recording_count}
            text={"Numbers of Recording"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={primaryColor}
            bg2={primaryColorWithAlpha}
            icon={<IoRecordingOutline color="#fff" />}
            number={dashboardInfo.pending_recording_count}
            text={"Numbers of Pending Recording"}
          />
        </Col>

      </Row>
    </div>
  );
}
