import React from "react";

import styles from "./styles/Navbar.module.scss";
import { IoIosNotificationsOutline } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";



export default function Navbar({ isSearchBar = true, isSettingButton = false, handleToggle, isCollapsed }) {


    return (
        <div className={styles.Navbar}>
            <div className={styles.titleContainer}>
                <span onClick={handleToggle} className={`${styles.toggleButton} ${isCollapsed ? '' : styles.rotate}`} >
                    {/* <GiHamburgerMenu /> */}
                    <img alt="menu" src="images/menu_btn_color.png" width={40} />
                    </span>
                {/* <h3 className={styles.title}>{title}</h3> */}
            </div>
            {isSearchBar ?
                <div className={styles.search}>
                    <div className={styles.searchButton}>
                        <AiOutlineSearch />
                    </div>
                    <input
                        type="text"
                        placeholder="Search "
                    // value={search}
                    // onChange={(e) => dispatch(setValue(e.target.value))}
                    />
                </div>
                : ''}



        </div >
    )
}