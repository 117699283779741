const baseUrl = (name = "") => {
  return window.env.BASE_URL;
};

const adminUrl = () => {
  return "http://api.slowyourroll.trangotechdevs.com:3001/api/admin/";
};

const initialToken = () => {
  return "ee0a260d-417f-45b6-acd7-f003d3ad386d";
};

export { adminUrl, baseUrl, initialToken };
