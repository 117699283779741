import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  terms: "",
  about: "",
  privacy: "",
  email:"",
  mobile_no:"",
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSettingInfo: (state, action) => {
      state.terms = action.payload.terms;
      state.about = action.payload.about;
      state.privacy = action.payload.privacy;
      state.email = action.payload.email;
      state.mobile_no = action.payload.mobile_no;
    },
    unsetSettingInfo: (state, action) => {
      state.terms = action.payload.terms;
      state.about = action.payload.about;
      state.privacy = action.payload.privacy;
      state.email = action.payload.email;
      state.mobile_no = action.payload.mobile_no;
    },
    // updateContent: (state, action) => {
    //   // console.log('update Users' + action.payload);
    //   state.all_content = action.payload;
    // },
    
  },
});


export const { setSettingInfo,  } = settingSlice.actions;

export default settingSlice.reducer;
