import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleLeft,
  FaHeadphones,
} from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/user_management.module.scss";
//
import { ShimmerTable } from "react-shimmer-effects";

import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { updateContent } from "../../features/contentSlice";
import {
  useDeleteAudioLoopMutation,
  useDeleteAudioMutation,
  useGetAllAudioQuery,
} from "../../services/contentApi"; // get data from API Servers

const ContentManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const [indicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");
  const [deleteInLoop, setDeleteInLoop] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const { data, isSuccess, refetch } = useGetAllAudioQuery({
    refetchOnMountOrArgChange: true,
  });

  var allContent = useSelector((state) => state.content.all_content);

  useEffect(() => {
    setRowData(allContent);
    console.log(rowData);
  }, [allContent, rowData]);

  useEffect(() => {
    if (data && isSuccess) {
      console.log("isSuccess = " + data.code);
      dispatch(updateContent(data.data));
    }
  }, [isSuccess, data, dispatch]);

  const [deleteAudio, { isLoading: isDeleting }] = useDeleteAudioMutation();
  const [deleteAudioLoop] = useDeleteAudioLoopMutation();

  const deleteData = async (userSlug) => {
    console.log(userSlug);

    let data, error;

    try {
      if (deleteInLoop) {
        console.log("Yes in Loop ");
        ({ data, error } = await deleteAudioLoop(userSlug));
      } else {
        console.log("NO in Loop ");
        ({ data, error } = await deleteAudio(userSlug));
      }

      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setDeleteInLoop(false);
    setEditedData("");
  };

  function indication() {
    if (isSuccess && rowData.length == 0) {
      return "No Record found.";
    } else {
      return <ShimmerTable row={5} col={4} />;
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Audio Name",
      headerStyle: {
        width: "200px",
        color: "black",
      },
      style: {
        color: "black",
      },
    },
    {
      dataField: "loop",
      text: "Loop",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => {
        return (
          <div> {row.loop_url.length === 0 ? 1 : row.loop_url.length} </div>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "user_name",
      text: "User",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
        color: "black",
      },
      style: {
        color: "black",
      },
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div>
        {row.loop_url.length === 0 ? (
          <div
            key={row.slug}
            className={`d-flex flex-row justify-content-between align-items-center my-2 p-2 rounded-4 ${styles.expandedRow}`}
          >
            <div className="d-flex flex-column">
              <b>{row.name}</b>
              <span className="fs-8"> {row.createdAt} </span>
            </div>
            <p className="m-0">{row.duration}</p>
            <audio controls>
              <source src={row.file_url} type="audio/mp3" />
            </audio>
            <div
              className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
              role="button"
              onClick={() => {
                console.log(row);
                setEditedData(row);
                handleDeleteModalOpen();
              }}
            >
              <span className={`d-flex align-items-center pe-1 `}>
                <MdDeleteForever />
              </span>
              <span>Delete</span>
            </div>
          </div>
        ) : (
          row.loop_url.map((data, index) => {
            return (
              <div
                key={index}
                className={`d-flex flex-row justify-content-between align-items-center my-2 p-2 rounded-4 ${styles.expandedRow}`}
              >
                <div className="d-flex flex-column">
                  <b>{data.name}</b>
                  <span className="fs-8"> {data.createdAt} </span>
                </div>
                <p className="m-0">{data.duration}</p>
                <audio controls>
                  <source src={data.file_url} type="audio/mp3" />
                </audio>
                <div
                  className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                  role="button"
                  onClick={() => {
                    console.log(data);
                    setEditedData(data);
                    setDeleteInLoop(true);
                    handleDeleteModalOpen();
                  }}
                >
                  <span className={`d-flex align-items-center pe-1 `}>
                    <MdDeleteForever />
                  </span>
                  <span>Delete</span>
                </div>
              </div>
            );
          })
        )}
      </div>
    ),
    onlyOneExpanding: true, // this will expand only one row at a time.
    parentClassName: `${styles.expandedRow}`, // this class will apply expanded row.
    showExpandColumn: true, // this will create a column on left side with + - sign
    expandColumnPosition: "right", // this will set position of expanded column
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return (
        <b>
          <FaHeadphones />
        </b>
      );
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <FaArrowAltCircleDown />
          </b>
        );
      }
      return (
        <b>
          <FaArrowAltCircleLeft />
        </b>
      );
    },
  };

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Content Management"} />
        </div>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData}
            columns={columns}
            indication={indication}
            expandRow={expandRow}
          />
        }
      </div>
    </div>
  );
};

export default ContentManagementScreen;
