import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ text, onChange }) => {
  // Use a ref to access the quill instance directly
  const quillRef = useRef();

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  },[setMounted]);

const handleChangeValue = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor(); // get the Quill editor instance
      const currentText = editor.root.innerHTML; // get the HTML content
      console.log(currentText);
      onChange(currentText);
    }
  };

if (!mounted) {
return;
}

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      value={text}
    onChange={handleChangeValue}
      style={{ backgroundColor: "white", color: 'black',  }}
    />
  );
};

export default TextEditor;
