import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_count: "",
  recording_count: "",
  pending_recording_count: "",
};

export const dashboardSlice = createSlice({
  name: "dashboardInfo",
  initialState,
  reducers: {
    setDashboardInfo: (state, action) => {
      state.user_count = action.payload.user_count;
      state.recording_count = action.payload.recording_count;
      state.pending_recording_count = action.payload.pending_recording_count;
    },
    unsetDashboardInfo: (state, action) => {
      state.user_count = action.payload.api_token;
      state.recording_count = action.payload.recording_count;
      state.pending_recording_count = action.payload.pending_recording_count;
    },
  },
});

export const { setDashboardInfo, unsetDashboardInfo } = dashboardSlice.actions;

export default dashboardSlice.reducer;
