import React from 'react';

import styles from './styles/Layout.module.scss'

import Details from './Details';
import Menu from './Menu';

export default function MobileLayout({ route = '', setRoute }) {
    return (
        <div className={styles.Layout}>
            {route ?
                <Details
                    isMobile={true}
                    route={route}
                    setRoute={setRoute}
                /> :
                <Menu
                    route={route}
                    setRoute={setRoute}
                />
            }

        </div>
    )
}