import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  slug: "",
  username: "",
  email: "",
  mobile_no: "",
  image_url: "",
  is_blocked: 0,
  users:[],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.slug = action.payload.slug;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.mobile_no = action.payload.mobile_no;
      state.image_url = action.payload.image_url;
      state.is_blocked = action.payload.is_blocked;
    },
    unsetUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.slug = action.payload.slug;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.mobile_no = action.payload.mobile_no;
      state.image_url = action.payload.image_url;
      state.is_blocked = action.payload.is_blocked;
    },
    updateUsers: (state, action) => {
      // console.log('update Users' + action.payload);
      state.users = action.payload;
    },
    
  },
});


export const { setUserInfo, unsetUserInfo, updateUsers } = userSlice.actions;

export default userSlice.reducer;
