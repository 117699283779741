import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  api_token : '',
  email : '',
  image_url : '',
  name : '',
  slug : '',
}

export const authSlice = createSlice({
  name: 'authInfo',
  initialState,
  reducers: {
     setAuthInfo:(state, action)=> {
        state.api_token = action.payload.api_token;
        state.email = action.payload.email;
        state.image_url = action.payload.image_url;
        state.name = action.payload.name;
        state.slug = action.payload.slug;
      },
      unsetAuthInfo:(state, action)=> {
        state.api_token = action.payload.api_token;
        state.email = action.payload.email;
        state.image_url = action.payload.image_url;
        state.name = action.payload.name;
        state.slug = action.payload.slug;
     },
     
  },
})

export const { setAuthInfo, unsetAuthInfo } = authSlice.actions

export default authSlice.reducer