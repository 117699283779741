import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";

const EditUserModal = ({
  handleEditModalClose,
  data,
  handleUpdate,
  updateLoading,
}) => {
  const { handleSubmit, control } = useForm({});

  const [userSlug, setUserSlug] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const statusList = [
    { label: "Active", value: false },
    { label: "Block", value: true },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      setUserSlug(data.slug);
      setUserEmail(data.email);
      if (data.is_blocked === 0) {
        setSelectedStatus("Active");
      } else {
        setSelectedStatus("Block");
      }
    }
  }, [data, mounted]);

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4 text-black">
            <p className="fs-4 fw-medium m-0 primary-text">
              {" "}
              {data ? "Edit User" : "Add User"}{" "}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form onSubmit={handleSubmit(handleUpdate)}>
              <Row>
                <Col md={12}>
                  <p className="m-0 text-black">User Email</p>

                  <Input
                    classes="mt-2 mb-3"
                    type="email"
                    placeholder="Enter user email"
                    value={userEmail}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p className="m-0 text-black">Status</p>
                  <Controller
                    control={control}
                    name="is_blocked"
                    rules={{}}
                    defaultValue={selectedStatus}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <SelectFieldComponent
                        firstOption={
                          _.isEmpty(selectedStatus)
                            ? "Select Status"
                            : selectedStatus
                        }
                        optionsList={statusList}
                        onSelectChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      updateLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={updateLoading ? <BeatLoader size={10} /> : "Update"}
                    // onClick={handleSubmit}
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditUserModal;
