import React from "react";
import { Navigate } from "react-router-dom";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";
import { getToken } from "../../services/localStorageService";

export default function PublicRoute({ children }) {

    const token = getToken() //todo:
    // const token = GetTokenLocalStorage() //todo:
    // const token = true;


    return (
        token ? <Navigate to={baseRoute(WEB_ROUTES.DASHBOARD)} replace /> : children

    );
}
